
import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonFileService } from './jsondata.service';

@Injectable()
export class UploadService {

  constructor(private http: HttpClient, private _jsonFile: JsonFileService) {}

  // file from event.target.files[0]
  uploadFile(url: string, file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('upload', file);

    const params = new HttpParams();
    const options = {
      params: params,
      Content: formData,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

  // upload file to member fold.
  uploadMemberFile1(memberId: string, file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('upload', file);

    const params = new HttpParams();
    const options = {
      params: params,
//      Content: formData,
      reportProgress: true,
    };

    const url = this._jsonFile.apiUrl + '/upload/';
    alert('formData: ' + JSON.stringify(formData));
    const req = new HttpRequest('POST', url, formData, options);

   // req.headers.keys['member-id'] = memberId;
  //  req.headers.keys['x-access-token'] = sessionStorage.getItem('id_token');

    return this.http.request(req);
  }

  uploadMemberFile(memberId: string, file: File) {


        const formData: FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        const headers = new Headers();
        /** No need to include Content-Type in Angular 4 */
        // headers.append('Content-Type', 'multipart/form-data');
       // headers.append('Accept', 'application/json');
       // const options = new RequestOptions({ headers: headers });
       alert(this._jsonFile.apiUrl + '/upload/');
       const options = {};
        this.http.post(this._jsonFile.apiUrl + '/upload/', formData, options)
            .map(res => res)
            .catch(error => Observable.throw(error))
            .subscribe(
                data => { alert('result: ' + JSON.stringify(data)); console.log('success'); },
                error => console.log(error)
            );

}

}
